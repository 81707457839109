<template>
  <div>
    <h1 class="mb-0">Ingreso</h1>
    <p>Ingrese su email y password registrado para acceder</p>
    <tab-content id="pills-tabContent-1" class="mt-0">
      <tab-content-item :active="true" id="pills-firebase-fill" aria-labelled-by="pills-firebase-tab-fill">
        <sign-in1-form formType="firebase" email="admin@vito.com" password="admin123"></sign-in1-form>
      </tab-content-item>
    </tab-content>
  </div>
</template>

<script>
import SignIn1Form from './Forms/SignIn1Form'

export default {
  name: 'SignIn1',
  components: { SignIn1Form },
  data: () => ({}),
  mounted () {
    const loggedIn = localStorage.getItem('access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      // this.$router.push({ name: 'mini.dashboard.home-2' })
    }
  }
}
</script>
