<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
       <b-form-checkbox v-model="user.switch" name="check-button" switch>
       <p v-if="user.switch">Ingresar como <b>ADMINISTRADOR</b> </p>
       <p v-else>Ingresar como <b>USUARIO</b> </p>
    </b-form-checkbox>
      <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group">
          <label for="emailInput">Email</label>
          <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="emailInput" aria-describedby="emailHelp"
                 v-model="user.email" placeholder="Enter email" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <!-- <router-link to="/auth/password-reset1" class="float-right">
            Forgot password?
          </router-link> -->
          <input type="password"  :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="user.password" placeholder="Password" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" :id="formType">
          <label class="custom-control-label" :for="formType">Recordar</label>
        </div>
        <button  type="submit" class="btn btn-primary float-right" :disabled="loading">Ingresar</button>
      </div>
      <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            No tienes cuenta de usuario?
            <router-link to="/auth/sign-up1" class="iq-waves-effect pr-4">
              Crear cuenta
            </router-link>
          </span>
        <!-- <social-login-form></social-login-form> -->
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
// import firebase from 'firebase'
// import SocialLoginForm from './SocialLoginForm'
import { core } from '../../../../config/pluginInit'
import { mapGetters } from 'vuex'
import { db, firebaseApp } from '../../../../config/firebase'

export default {
  name: 'SignIn1Form',
  // components: { SocialLoginForm },
  props: ['formType', 'email', 'password'],
  data: () => ({
    user: {
      switch: false,
      email: '',
      password: '',
      prueba: null
    },
    loading: false
  }),
  mounted () {
    core.index()
  },
  computed: {
    ...mapGetters({
      stateUsers: 'Setting/usersState'
    })
  },
  methods: {
    onSubmit () {
      this.firebaseLogin()
    },
    firebaseLogin () {
      this.loading = true
      firebaseApp.auth().signInWithEmailAndPassword(this.user.email.toLowerCase(), this.user.password).then((user) => {
        const firebaseUser = firebaseApp.auth().currentUser.providerData[0]
        this.$store.dispatch('Setting/authUserAction', {
          auth: true,
          authType: 'firebase',
          user: {
            id: firebaseUser.uid,
            name: firebaseUser.displayName,
            mobileNo: firebaseUser.phones,
            email: firebaseUser.email,
            profileImage: firebaseUser.photoURL
          }
        })
        localStorage.setItem('user', JSON.stringify(firebaseUser))
        localStorage.setItem('uid', user.user.uid)
        if (this.user.switch === false) {
          this.isRegistred()
        } else if (this.user.switch === true) {
          this.isAdminRegistred()
        }
      }).catch((err) => {
        if (err.code === 'auth/user-not-found') {
          core.showSnackbar('error', 'These credentials do not match our records.')
          this.loading = false
        } else {
          core.showSnackbar('error', err.message)
          this.loading = false
        }
      })
    },
    isRegistred () {
      db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts')
        .where('email', '==', this.user.email.toLowerCase())
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            localStorage.removeItem('user')
            localStorage.removeItem('access_token')
            firebaseApp.auth().signOut().then(() => {
              core.showSnackbar('error', 'No está registrado como usuario')
            }).catch((error) => {
              console.log(error)
            })
          } else {
            querySnapshot.forEach((doc) => {
              console.log('Si está')
              console.log('userDocId', doc.id)
              localStorage.setItem('userDocId', doc.id)
              localStorage.setItem('userInformation', JSON.stringify(doc.data()))
              // this.$router.push({ path: '/superAdmin.sing-in' })
              this.$router.push({ path: `/staffProfile/${doc.id}/` })
              console.log('rutas', this.$router)
            })
          }
        }).catch((error) => {
          console.log('Error getting documents: ', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    isAdminRegistred () {
      db.collection('administrators')
        .where('email', '==', this.user.email)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            localStorage.removeItem('user')
            localStorage.removeItem('access_token')
            core.showSnackbar('error', 'No está registrado como Admin')
          } else {
            querySnapshot.forEach((doc) => {
              if (doc.data().status === true) {
                localStorage.setItem('userDocId', doc.id)
                localStorage.setItem('userInformation', JSON.stringify(doc.data()))
                this.$router.push({ name: 'dashboardAdmin.home' })
              } else {
                firebaseApp.auth().signOut().then(() => {
                  core.showSnackbar('error', 'You do not have permission to access, please contact the support area')
                })
              }
            })
          }
        }).catch((error) => {
          console.log('Error getting documents: ', error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
